import styled from 'styled-components/macro';
import { darkerGray, hardWhite, outline } from '../../../../config/colors';

export const Pills = styled.div`
  max-height: 180px;
  overflow-y: auto;
  ${({ addMargin }) => (addMargin ? 'margin-bottom: 10px;' : null)};
`;

export const Pill = styled.div`
  color: black;
  display: flex;
  align-items: center;
  background-color: ${hardWhite};
  border-radius: 99999px;
  padding: 6px 8px;
  width: 100%;
  opacity: ${({ lowOpacity }) => (lowOpacity ? '0.6' : '1')};
  ${({ addMargin }) => (addMargin ? 'margin-bottom: 5px;' : null)};
  ${({ isClickable }) => (isClickable ? '&:hover {cursor: pointer}' : null)};
`;

export const PillImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  object-fit: cover;
  min-width: 20px;
`;

export const PillLabel = styled.span`
  padding: 0px 8px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const PillRemoveButton = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
  outline: none;
  color: ${darkerGray};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: ${outline};
  }

  width: 24px;
  height: 24px;
`;
