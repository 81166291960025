import { fromJS, getIn, updateIn } from 'immutable';
import { actionStrings } from './actions';
import { entities, MIN_DATE } from '../../config/config';
import { actionStrings as authActionStrings } from '../../redux/auth/actions';
import { actionStrings as workspaceActionStrings } from '../workspace/actions';
import {
  selectWorkspace,
  getEntityLoadObject,
  getEntityErrorObject,
  filterLoadingObjects,
  getPagedEntityStructure,
} from '../redux.helpers';
import { matchEntity, matchChildren } from './reducerUtils';

const initCacheState = {
  collections: {},
  all: [],
};

const initState = fromJS({
  // All workspaces
  workspaces: initCacheState,

  // Selected workspace
  // id, name
  currentWorkspace: {},

  // Selected category. Used for filtering issues
  selectedCategory: '',

  // Notifications

  notifications: {},

  // Issues
  issues: initCacheState,

  // Autocomplete templates
  templates: initCacheState,

  // From Selected Workspace
  statuses: [],
  priorities: [],
  assignees: [],
  permissions: [],

  // Loading { type: Workspace | Issue, loading: boolean, requestType: string }
  loading: [],
  // Error: { error: {}, entityType, time: date }
  error: [],
  users: initCacheState,
  categories: initCacheState,
  language: {},
  client: {},
});

const getOtherEnitities = (action) => (l) =>
  l.entityType !== action.payload.entityType;

const oneLessLoadingOfType = (type, array) => {
  let found = false;
  const returnArray = [];

  for (const item of array) {
    if (!found && item.entityType === type) {
      found = true;

      continue;
    }

    returnArray.push(item);
  }

  return returnArray;
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.LOAD_ENTITY:
    case actionStrings.LOAD_ENTITY_PAGED: {
      const newEntry = {
        entityType: action.payload.entityType,
        entityId: action.payload.entityId,
        isLoading: true,
        time: new Date(),
        meta: action.payload.meta,
      };

      return state.set(entities.LOADING.reduxProp, [
        ...state.get(entities.LOADING.reduxProp),
        newEntry,
      ]);
    }
    case actionStrings.SEARCH_ENTITY: {
      const newEntry = {
        entityType: action.payload.entityType,
        searchTerm: action.payload.searchTerm,
        isLoading: true,
        time: new Date(),
      };

      return state.set(entities.SEARCHING.reduxProp, [
        ...state.get(entities.SEARCHING.reduxProp),
        newEntry,
      ]);
    }
    case actionStrings.ENTITY_LOADED: {
      const loadings = oneLessLoadingOfType(
        action.payload.entityType,
        state.get(entities.LOADING.reduxProp)
      );

      return state.set(entities.LOADING.reduxProp, loadings);
    }
    case actionStrings.LOAD_ENTITY_SUCCESS: {
      if (state.get(entities[action.payload.entityType].reduxProp)) {
        const loadingStates = state
          .get(entities.LOADING.reduxProp)
          .filter(getOtherEnitities(action));

        return state
          .set(
            entities[action.payload.entityType].reduxProp,
            action.payload.payload
          )
          .set(entities.LOADING.reduxProp, loadingStates);
      }

      return state;
    }
    case actionStrings.UPDATE_ENTITY: {
      const basePath = entities[action.payload.entityType].reduxProp;
      const entityData = state.get(basePath);
      const newLoadingData = state
        .get(entities.LOADING.reduxProp)
        .filter(getOtherEnitities(action));

      if (entityData && entityData.data) {
        const dataPath = [basePath, 'data'];
        const currentData = getIn(state, dataPath);
        const newData = action.payload.payload;
        const newDataArray = currentData.map((currentData) =>
          currentData.id === newData.id ? newData : currentData
        );

        return state
          .setIn(dataPath, newDataArray)
          .set(entities.LOADING.reduxProp, newLoadingData);
      }

      const newData = getPagedEntityStructure();
      newData.data = [action.payload.payload];

      // I am not sure if we neeed this. Moved to current to not mess with cache
      return state
        .setIn([basePath, 'current'], newData)
        .set(entities.LOADING.reduxProp, newLoadingData);
    }
    case actionStrings.UPDATE_SINGLE_ENTITY: {
      const item = action.payload.payload;
      const entityType = entities[action.payload.entityType].reduxProp;
      const id = item.id;

      return state
        .set(
          entityType,
          state
            .get(entityType)
            .toJS()
            .map((entity) => {
              if (id === entity.id) {
                return item;
              }

              if (entity.children && entity.children.length) {
                entity.children = entity.children.map((child) =>
                  child.id === id ? item : child
                );

                return entity;
              }

              if (entity.children && item.parent_id) {
                entity.children.push(item);
              }

              return entity;
            })
        )
        .set(
          entities.LOADING.reduxProp,
          state
            .get(entities.LOADING.reduxProp)
            .filter((l) => l.entityType !== entityType)
        );
    }
    case actionStrings.LOAD_ENTITY_ERROR: {
      const filteredStatuses = state
        .get(entities.LOADING.reduxProp)
        .filter((l) => l.entityType !== action.payload.entityType);
      const currentErrors = state.get(entities.ERROR.reduxProp) || [];
      const errorArray = [
        ...currentErrors,
        getEntityErrorObject(action.payload),
      ];

      return state
        .set(entities.LOADING.reduxProp, filteredStatuses)
        .set(entities.ERROR.reduxProp, errorArray);
    }

    case actionStrings.GET_NOTIFICATIONS: {
      return state.set(
        entities.NOTIFICATION.reduxProp,
        action.payload.notifications
      );
    }
    case actionStrings.SELECT_CURRENT_WORKSPACE: {
      const selectedWorkspace = selectWorkspace(
        state.get('workspaces'),
        action.payload.id
      );
      const hasId = Object.prototype.hasOwnProperty.call(selectWorkspace, 'id');

      return !selectedWorkspace || (selectedWorkspace && !hasId)
        ? state
            .set('currentWorkspace', selectedWorkspace)
            .set(
              entities.PRIORITY.reduxProp,
              selectWorkspace[entities.PRIORITY.reduxProp] || []
            )
            .set(
              entities.STATUS.reduxProp,
              selectWorkspace[entities.STATUS.reduxProp] || []
            )
            .set(
              entities.ASSIGNEE.reduxProp,
              selectWorkspace[entities.ASSIGNEE.prop] || []
            )
        : state;
    }

    case actionStrings.SELECT_CATEGORY:
      return state.set('selectedCategory', action.payload.id);

    case actionStrings.CLEAR_CATEGORY:
      return state.set('selectedCategory', '');

    case actionStrings.ENTITY_REQUEST:
      return state.set(entities.LOADING.reduxProp, [
        ...state.get(entities.LOADING.reduxProp),
        getEntityLoadObject(action.payload),
      ]);

    case actionStrings.MULTIPART_ENTITY_REQUEST:
      return state.set(entities.LOADING.reduxProp, [
        ...state.get(entities.LOADING.reduxProp),
        getEntityLoadObject(action.payload),
      ]);

    case actionStrings.ENTITY_REQUEST_SUCCESS: {
      const newLoadingState = filterLoadingObjects(
        state.get(entities.LOADING.reduxProp),
        action.payload
      );

      return state.set(entities.LOADING.reduxProp, newLoadingState);
    }

    case actionStrings.ENTITY_REQUEST_WITH_PAGING_SUCCESS: {
      const entity = action.payload.entityType;
      const pagedEntity = entities[entity].reduxProp;
      const apiPropPaged = entities[entity].name.toLowerCase();
      const filteredLoadingStates = filterLoadingObjects(
        state.get(entities.LOADING.reduxProp),
        action.payload
      );

      return state
        .set(entities.LOADING.reduxProp, filteredLoadingStates)
        .updateIn(
          [pagedEntity, 'data'],
          action.payload.data[apiPropPaged],
          (x) => x
        );
    }

    case actionStrings.ADD_TO_COLLECTION: {
      const reduxProp = action.payload.entityType.reduxProp;

      const pagingInfo = {
        current_page: action.payload.data.current_page,
        last_page: action.payload.data.last_page,
        prev_page_url: action.payload.data.prev_page_url,
        next_page_url: action.payload.data.next_page_url,
        total_items: action.payload.data.total,
      };
      const paramsWithPaging = { ...action.payload.parameters, ...pagingInfo };
      let data = action.payload.data;

      // If result set is paged than we have this weird data.data combination.
      if (action.payload.data.data) {
        data = action.payload.data.data;
      }

      const dataForCollection = {
        ids: data.map((x) => x.id),
        lastUpdated: new Date(),
        filter: paramsWithPaging,
      };

      state = state
        .setIn([reduxProp, 'collections', 'currentFilter'], paramsWithPaging)
        .setIn([reduxProp, 'collections', 'currentKey'], action.payload.key)
        .setIn(
          [reduxProp, 'collections', action.payload.key],
          dataForCollection
        );

      const all = state.getIn([reduxProp, 'all']);
      for (let i = 0; i < data.length; i++) {
        const itemFromAll = all.find((x) => x.id === data[i].id);

        if (itemFromAll) {
          const indexToUpdate = state
            .getIn([reduxProp, 'all'])
            .findIndex((x) => x.id === data[i].id);
          state = state.setIn([reduxProp, 'all', indexToUpdate], data[i]);
        } else {
          const newState = state.getIn([reduxProp, 'all']).insert(0, data[i]);

          state = state.setIn([reduxProp, 'all'], newState);
        }
      }

      return state;
    }

    case actionStrings.ADD_SINGLE_TO_COLLECTION: {
      const reduxProp = action.payload.entityType.reduxProp;

      const data = action.payload.data;

      const dataForCollection = {
        ids: [data.id],
        lastUpdated: new Date(),
      };

      state = state
        .setIn([reduxProp, 'collections', 'currentKey'], action.payload.key)
        .setIn(
          [reduxProp, 'collections', action.payload.key],
          dataForCollection
        );

      const all = state.getIn([reduxProp, 'all']);
      const itemFromAll = all.find((x) => x.id === data.id);

      if (itemFromAll) {
        const indexToUpdate = state
          .getIn([reduxProp, 'all'])
          .findIndex((x) => x.id === data.id);
        state = state.setIn([reduxProp, 'all', indexToUpdate], data);
      } else {
        const newState = state.getIn([reduxProp, 'all']).insert(0, data);

        state = state.setIn([reduxProp, 'all'], newState);
      }

      return state;
    }

    case actionStrings.ADD_ITEM_TO_COLLECTION: {
      const reduxProp = action.payload.entityType.reduxProp;
      const item = action.payload.item;
      const all = state.getIn([reduxProp, 'all']).toJS();
      const collections = state.getIn([reduxProp, 'collections']).toJS();

      if (Object.keys(collections).length) {
        const { filter = {}, ids = [] } = collections[''] || {};

        const newCollections = {
          ...collections,
          '': {
            filter: {
              ...filter,
              total_items: (filter.total_items ?? 0) + 1,
            },
            ids: ids.concat(item.id),
            lastUpdated: new Date(),
          },
        };

        all.push(item);

        state = state.mergeDeepIn([reduxProp], {
          all: all,
          collections: newCollections,
        });
      }

      return state;
    }

    case actionStrings.SET_CURRENT_PARAMETERS:
      return state
        .setIn(
          [action.payload.entityType.reduxProp, 'collections', 'currentFilter'],
          action.payload.parameters
        )
        .setIn(
          [action.payload.entityType.reduxProp, 'collections', 'currentKey'],
          action.payload.key
        );

    case actionStrings.INVALIDATE_COLLECTION: {
      state
        .getIn([action.payload.entityType.reduxProp, 'collections'])
        .map((item, key) => {
          if (key !== 'currentKey' && key !== 'currentFilter') {
            item.lastUpdated = MIN_DATE;
          }

          return item;
        });

      return state;
    }

    case actionStrings.UPDATE_IN_ALL: {
      const reduxProp = action.payload.entityType.reduxProp;
      const item = action.payload.item;
      const entities = state.getIn([reduxProp, 'all']).toJS();
      let entity;
      let result;

      for (let index = 0; index < entities.length; index++) {
        entity = entities[index];

        result = matchEntity(entity, item);

        if (result.match) {
          return state.setIn([reduxProp, 'all', index], result.item);
        }

        result = matchChildren(entity, item);

        if (result.match) {
          return state.setIn([reduxProp, 'all', index], result.item);
        }
      }

      return state.setIn([reduxProp, 'all', entities.length], item);
    }

    case workspaceActionStrings.UPDATE_USER_LIST: {
      const wsIndex = state
        .get(entities.WORKSPACE.reduxProp)
        .find((ws) => ws.id === action.payload.wsId);

      return updateIn(
        state,
        [entities.WORKSPACE.reduxProp, wsIndex, action.payload.updatedUserType],
        () => [...action.payload.newUsers]
      );
    }

    case actionStrings.ENTITY_REQUEST_ERROR: {
      const errors = state.get(entities.ERROR.reduxProp) || [];

      return state
        .set(
          entities.LOADING.reduxProp,
          filterLoadingObjects(
            state.get(entities.LOADING.reduxProp),
            action.payload
          )
        )
        .set(entities.ERROR.reduxProp, [
          ...errors,
          getEntityErrorObject(action.payload),
        ]);
    }
    case authActionStrings.LOGOUT:
      return initState.set(
        entities.LANGUAGE.reduxProp,
        state.get(entities.LANGUAGE.reduxProp)
      );
    default:
      return state;
  }
};
