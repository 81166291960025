import { useEffect, useState } from 'react';
import { MODAL_STEPS, MODE, TABS } from '../constants';
import { combineArrays } from '../../../utils/core';

const useList = ({
  mode,
  forceShowModal,
  hideAddButton,
  selectedItems,
  onChange,
  onClose,
  workspaceIds,
  disabled,
  customQueryParams,
  showModalWhenLastItemIsRemoved,
} = {}) => {
  const [isVisible, setIsVisible] = useState(forceShowModal ?? false);
  const toggleModalState = () => setIsVisible((prevState) => !prevState);
  const handleModalClose = () => setIsVisible(false);
  const [modalStep, setModalStep] = useState(MODAL_STEPS.list);
  const [localSelectedItems, setLocalSelectedItems] = useState([]);
  const isSingleSelect = mode === MODE.single;
  const [search, setSearch] = useState('');
  const [initQueryParams, setInitQueryParams] = useState({});
  const [tab, setTab] = useState(TABS[0].value);

  //set init query params
  useEffect(() => {
    if (workspaceIds?.length) {
      const { includeOnTabs, ...restCustomQueryParams } =
        customQueryParams ?? {};
      setInitQueryParams({
        workspace_ids: workspaceIds,
        ...(restCustomQueryParams && includeOnTabs?.includes(tab)
          ? restCustomQueryParams
          : {}),
      });
    }
  }, [customQueryParams, tab, workspaceIds]);

  /** `indicator` to display add button */
  const displayAddButton =
    (!localSelectedItems?.length || !isSingleSelect) &&
    !hideAddButton &&
    !disabled;

  useEffect(() => {
    if (selectedItems) setLocalSelectedItems(selectedItems);
  }, [selectedItems]);

  const _handleSingleRemove = () => {
    setLocalSelectedItems([]);
    if (showModalWhenLastItemIsRemoved) {
      toggleModalState();
    } else {
      onChange(null);
    }
  };

  const _handleMultiRemove = (id) => {
    const newItems = localSelectedItems.filter((item) => item.id !== id);
    setLocalSelectedItems(newItems);
    onChange(newItems.map((item) => item.id));
  };

  const handleRemove = (id) => {
    if (isSingleSelect) {
      _handleSingleRemove();
      return;
    }

    _handleMultiRemove(id);
  };

  const handleCancel = () => {
    const newLocalSelectedItems = selectedItems ?? [];
    setLocalSelectedItems(newLocalSelectedItems);
    setInitQueryParams({ workspace_ids: workspaceIds });
    toggleModalState();
    setSearch('');

    const _value = isSingleSelect
      ? selectedItems?.[0]?.id
      : selectedItems?.map((item) => item.id);

    onChange(_value, newLocalSelectedItems);

    onClose();
  };

  const handleTabChange = (newTab) => {
    if (search?.length)
      setInitQueryParams((prevState) => ({ ...prevState, search }));
    setTab(newTab);
  };

  const handleTriggerAddModal = () => {
    const step =
      tab === TABS[0].value ? MODAL_STEPS.addUser : MODAL_STEPS.addCollaborator;
    setModalStep(step);
  };

  const _handleSingleSelect = (item) => {
    //delete if existing
    if (localSelectedItems[0]?.id === item.id) {
      setLocalSelectedItems([]);
      return;
    }

    setLocalSelectedItems([item]);
  };

  const _handleMultiSelect = (item) => {
    const selectedIds = new Set(localSelectedItems.map((item) => item.id));

    if (!selectedIds.has(item.id)) {
      setLocalSelectedItems((prevState) => [...prevState, item]);
    } else {
      const newLocalSelectedItems = localSelectedItems.filter(
        (prevItem) => prevItem.id !== item.id
      );

      setLocalSelectedItems(newLocalSelectedItems);
    }
  };

  const handleSelect = (item) => {
    if (isSingleSelect) {
      _handleSingleSelect(item);
      return;
    }

    _handleMultiSelect(item);
  };

  const handleSave = () => {
    const hasNotInWorkspaceitems = localSelectedItems.some(
      (item) => item.isNotInWorkspace
    );

    if (hasNotInWorkspaceitems) {
      setModalStep(MODAL_STEPS.addToWorkspace);
      return;
    }

    const _value = isSingleSelect
      ? localSelectedItems[0].id
      : localSelectedItems.map((item) => item.id);

    onChange(_value, localSelectedItems);
    toggleModalState();
  };

  const handleToogleModalState = () => {
    if (!workspaceIds?.length) return;

    toggleModalState();
  };

  const handleAddSuccessCallback = (newItems) => {
    setIsVisible(false);
    setModalStep(MODAL_STEPS.list);

    const _newItems = isSingleSelect
      ? newItems
      : combineArrays(localSelectedItems, newItems);

    setLocalSelectedItems(_newItems);

    const _value = isSingleSelect
      ? _newItems[0].id
      : _newItems.map((item) => item.id);

    setModalStep(MODAL_STEPS.addSuccess);

    //delay for animation
    setTimeout(() => {
      setIsVisible(true);
    }, 500);

    onChange(_value, _newItems);
  };

  return {
    isVisible,
    modalStep,
    displayAddButton,
    localSelectedItems,
    tab,
    search,
    initQueryParams,
    setSearch,
    setLocalSelectedItems,
    setModalStep,
    handleRemove,
    handleCancel,
    handleTabChange,
    handleTriggerAddModal,
    handleSelect,
    handleSave,
    handleToogleModalState,
    handleAddSuccessCallback,
    toggleModalState,
    handleModalClose,
  };
};

export default useList;
