import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { WowBlock, WowIcon, WowLabelText } from '../../../WowBasicElements';
import { FormattedMessage } from 'react-intl';
import { AvatarWrapper, EditIcon, InputWrapper } from './styles';
import Input from '../../../Input';
import { INPUT_MAX_LENGTH } from '../../constants';
import { gradientBlue, newRed } from '../../../../config/colors';
import Avatar from '../../../Avatar/Avatar';

const Fields = (props) => {
  const {
    id: userId,
    fields,
    isActive,
    handleChange,
    handleDelete,
    errors,
    handleSetActive,
  } = props;

  return fields.map((field) => {
    const { fieldId, value, name, labelKey, isOptional, logo } = field;

    return (
      <Fragment key={fieldId}>
        {!isOptional || (isOptional && isActive) ? (
          <>
            {!logo ? (
              <WowLabelText bold>
                {isActive ? (
                  <FormattedMessage id={labelKey} />
                ) : (
                  <>
                    <FormattedMessage id="user" /> {userId}{' '}
                  </>
                )}
                {isOptional ? (
                  <>
                    {' ( '}
                    <FormattedMessage id="optional" />
                    {' )'}
                  </>
                ) : null}
              </WowLabelText>
            ) : null}

            <InputWrapper align={logo ? 'center' : null}>
              {logo ? (
                <AvatarWrapper>
                  <Avatar imageUrl={logo} />
                </AvatarWrapper>
              ) : null}
              <WowBlock>
                <Input
                  enableFocus={false}
                  value={value}
                  onChange={(e) =>
                    handleChange({ ...field, value: e.target.value })
                  }
                  maxLength={INPUT_MAX_LENGTH}
                  autoFocus={fieldId === 1}
                  name={name}
                  error={!!errors[userId] && fieldId === 1}
                  errorKey={errors[userId]}
                />
              </WowBlock>
              {!isActive ? (
                <>
                  <EditIcon
                    className="icon icon-pen"
                    display="flex"
                    spaceRight="0"
                    spaceLeft="10"
                    clickable
                    onMouseDown={() => handleSetActive(userId)}
                    size="20"
                    color={gradientBlue}
                  />
                  <WowIcon
                    className="icon icon-close"
                    display="flex"
                    spaceRight="0"
                    spaceLeft="10"
                    clickable
                    onMouseDown={() => handleDelete(userId)}
                    size="13"
                    color={newRed}
                  />
                </>
              ) : null}
            </InputWrapper>
          </>
        ) : null}
      </Fragment>
    );
  });
};

Fields.propTypes = {
  fields: PropTypes.array.isRequired,
};

export default Fields;
