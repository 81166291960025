import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import ButtonAdd from '../ButtonAdd';
import Modal, { SIZE } from '../Modal';
import Selected from './components/Selected';
import Tabs from './components/Tabs';
import { ADD_TO_WORKSPACE_CONFIG, MODAL_STEPS, MODE } from './constants';
import useList from './hooks/useList';
import { Wrapper } from './styles';
import ModalFooterWrapper from './components/ModalFooterWrapper';
import ModalAddUser from './components/ModalAddUser';
import ModalAddCollaborator from './components/ModalAddCollaborator';
import Space from '../Space';
import View from './components/View';
import ModalAddToWorkspace from './components/ModalAddToWorkspace';
import { WowTooltipWrapper } from '../WowBasicElements';
import Tooltip from '../Tooltip';
import { getUser } from '../../redux/user/selectors';
import ModalSuccess from '../ModalSuccess';

const ModalUsers = (props) => {
  const {
    mode = MODE.multi,
    onChange,
    selectedItems,
    hideAddButton,
    testIds,
    forceShowModal,
    tooltipConfig,
    lowOpacity,
    disabled,
    title,
    onClose = () => {},
    shouldShowTabs,
    workspaceIds,
    workspaceName,
    showSelected = true,
    canInviteLimitedCollaboratorsOnly,
    showModalWhenLastItemIsRemoved,
    customQueryParams,
  } = props;

  const {
    isVisible,
    modalStep,
    displayAddButton,
    localSelectedItems,
    tab,
    search,
    initQueryParams,
    setSearch,
    setModalStep,
    handleRemove,
    handleCancel,
    handleTabChange,
    handleTriggerAddModal,
    handleSelect,
    handleSave,
    handleToogleModalState,
    handleAddSuccessCallback,
    toggleModalState,
    handleModalClose,
  } = useList({
    mode,
    selectedItems,
    hideAddButton,
    forceShowModal,
    onChange,
    onClose,
    workspaceIds,
    disabled,
    customQueryParams,
    showModalWhenLastItemIsRemoved,
  });

  const loggedInUser = useSelector(getUser);
  const isAdmin = loggedInUser?.role === 'admin';

  return (
    <Wrapper flexGrow={!showSelected ? 0 : null}>
      {showSelected ? (
        <Selected
          items={localSelectedItems}
          tooltipConfig={tooltipConfig}
          lowOpacity={lowOpacity}
          disabled={disabled}
          handleRemove={handleRemove}
          toggleModalState={toggleModalState}
        />
      ) : null}

      {displayAddButton ? (
        <Tooltip
          tooltipContent={
            !workspaceIds?.length ? (
              <WowTooltipWrapper>
                <FormattedMessage id="tooltip_select_assignee" />
              </WowTooltipWrapper>
            ) : null
          }
        >
          <ButtonAdd
            data-testid={testIds?.addButtonDataTestId}
            onClick={handleToogleModalState}
          />
        </Tooltip>
      ) : null}

      <Modal
        title={title}
        isOpen={isVisible && modalStep === MODAL_STEPS.list}
        onClose={handleCancel}
        size={SIZE.small}
        maxHeight="700px"
        dataTestId="modalUsers"
      >
        {shouldShowTabs ? (
          <>
            <Tabs active={tab} handleChange={handleTabChange} />
          </>
        ) : null}

        <View
          active={tab}
          handleTriggerAddModal={handleTriggerAddModal}
          handleSelect={handleSelect}
          selectedItems={localSelectedItems}
          isAdmin={isAdmin}
          search={search}
          setSearch={setSearch}
          initQueryParams={initQueryParams}
          canInviteLimitedCollaboratorsOnly={canInviteLimitedCollaboratorsOnly}
          shouldShowTabs={shouldShowTabs}
        />

        <Space height="20" />
        <ModalFooterWrapper
          handleCancel={handleCancel}
          isActionDisabled={!localSelectedItems.length}
          handleAction={handleSave}
        />
      </Modal>

      {isVisible && modalStep === MODAL_STEPS.addUser ? (
        <ModalAddUser
          isOpen={isVisible && modalStep === MODAL_STEPS.addUser}
          onClose={() => setModalStep(MODAL_STEPS.list)}
          workspaceIds={workspaceIds}
          mode={mode}
          handleAddSuccessCallback={handleAddSuccessCallback}
        />
      ) : null}

      {isVisible && modalStep === MODAL_STEPS.addCollaborator ? (
        <ModalAddCollaborator
          isOpen={isVisible && modalStep === MODAL_STEPS.addCollaborator}
          onClose={() => setModalStep(MODAL_STEPS.list)}
          workspaceIds={workspaceIds}
          mode={mode}
          handleAddSuccessCallback={handleAddSuccessCallback}
          canInviteLimitedCollaboratorsOnly={canInviteLimitedCollaboratorsOnly}
        />
      ) : null}

      {isVisible && modalStep === MODAL_STEPS.addToWorkspace ? (
        <ModalAddToWorkspace
          isOpen={isVisible && modalStep === MODAL_STEPS.addToWorkspace}
          onClose={() => setModalStep(MODAL_STEPS.list)}
          localSelectedItems={localSelectedItems}
          workspaceName={workspaceName}
          workspaceIds={workspaceIds}
          handleAddSuccessCallback={handleAddSuccessCallback}
          {...ADD_TO_WORKSPACE_CONFIG[mode]}
        />
      ) : null}

      <ModalSuccess
        isOpen={isVisible && modalStep === MODAL_STEPS.addSuccess}
        onClose={() => {
          setModalStep(MODAL_STEPS.list);
          handleModalClose();
        }}
      />
    </Wrapper>
  );
};

export default ModalUsers;
